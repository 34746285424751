import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cattle-import" }
const _hoisted_2 = { class: "echo-json-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_row, {
                  type: "flex",
                  justify: "center",
                  gutter: 10
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { span: 20 }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_el_upload, {
                                ref: "upload",
                                "file-list": _ctx.filesRef,
                                "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filesRef) = $event)),
                                "auto-upload": false,
                                multiple: false,
                                limit: 1,
                                "on-exceed": _ctx.onExceed
                              }, {
                                trigger: _withCtx(() => [
                                  _createVNode(_component_el_button, { type: "primary" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("select file")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createTextVNode(" データファイルをアップロードしてください ")
                                ]),
                                _: 1
                              }, 8, ["file-list", "on-exceed"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, {
                  type: "flex",
                  justify: "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              type: "primary",
                              onClick: _ctx.importData
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" インポート ")
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}