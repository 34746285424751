
import axios from 'axios'
import { format } from 'date-fns'
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { AuthPlugin } from '@/auth'
import print from 'vue3-print-nb'

interface PredictionResult {
  BMS: number,
  CORN?: {
    logits: Array<number>,
    probabilities: Array<number>,
  }
}

interface TimeStamp {
  createdAt?: string
  updatedAt?: string
}

interface User extends TimeStamp {
  id: string
  email: string
  name?: string
}

interface Example extends TimeStamp {
  bms: number
  image?: string
  trapeziusThickness: number
  trapeziusSubcutaneousFatThickness: number
  subcutaneousFatThickness: number
  latissimusThickness: number
}

interface Cattle extends TimeStamp {
  id: string
  farmer?: string
  birthday?: string
  sex?: string
  father?: string
  mothersFather?: string
  mothersMothersFather?: string
}

interface Prediction extends TimeStamp {
  id: number
  userId: string
  user: User
  cattleId: string
  cattle: Cattle
  shotAt: string
  shootingDate: string
  result: PredictionResult
  exampleSearch?: Example
}

export default defineComponent({
  name: 'Detail',
  directives: {
    print,
  },
  setup() {
    const route = useRoute()
    const auth: AuthPlugin | undefined = inject('Auth')
    if (!auth) {
      throw new Error('Auth plugin is not provided')
    }
    const backendUrl: string | undefined = inject('BackendUrl')
    if (!backendUrl) {
      throw new Error('BackendUrl is not provided')
    }
    const predictionBaseUrl: string | undefined = inject('PredictionsUrl')
    if (!predictionBaseUrl) {
      throw new Error('PredictionsUrl is not provided')
    }

    const prediction = ref<Prediction>()
    const predictionUrl = ref()
    const example = ref<Example>()

    const moonAge = computed(() => {
      if (prediction?.value?.shootingDate == null || prediction?.value?.cattle?.birthday == null) {
        return null
      }

      const shootingDate = new Date(prediction.value.shootingDate)
      const birthday = new Date(prediction.value.cattle.birthday)

      const moonAge =
        (shootingDate.getFullYear() - birthday.getFullYear()) * 12 +
        shootingDate.getMonth() -
        birthday.getMonth()

      return moonAge < 0 ? 0 : moonAge
    })

    const printObj = reactive({ id: 'detail' })

    const display = (
      value?: unknown,
      option: { prefix?: string; suffix?: string; placeholder?: string } = {}
    ): string => {
      const _option = { prefix: '', suffix: '', placeholder: '-', ...option }
      return value != null ? `${_option.prefix}${value}${_option.suffix}` : _option.placeholder
    }

    const formatDate = (date?: string, pattern = 'yyyy-MM-dd HH:mm:ss') => {
      return date && format(new Date(date), pattern)
    }

    const getPrediction = async () => {
      const idToken = (await auth.getIdTokenClaims({}))?.__raw

      const response = await axios.get(`${backendUrl}/v1/prediction/${route.params.id}`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })

      prediction.value = response.data

      const userId = prediction.value?.userId
      const cattleId = prediction.value?.cattleId
      const shootingDate = prediction.value?.shootingDate.replaceAll('-', '')
      predictionUrl.value = `${predictionBaseUrl}/${userId}/${cattleId}-${shootingDate}`
    }

    const getRank5Percentage = (): number|undefined => {
      const rank5proba = prediction.value?.result?.CORN?.probabilities[4]
      return rank5proba
        ? Math.max(10, Math.min(90, Math.round(rank5proba * 10) * 10))
        : undefined
    }

    const getExample = async () => {
      const idToken = (await auth.getIdTokenClaims({}))?.__raw
      const bms = prediction.value?.result.BMS
      const tt = prediction.value?.exampleSearch?.trapeziusThickness
      const tsft = prediction.value?.exampleSearch?.trapeziusSubcutaneousFatThickness
      const sft = prediction.value?.exampleSearch?.subcutaneousFatThickness
      const lt = prediction.value?.exampleSearch?.latissimusThickness

      if (bms == null || tt == null || tsft == null || lt == null) {
        return
      }

      const response = await axios.get(`${backendUrl}/v1/example`, {
        headers: { Authorization: `Bearer ${idToken}` },
        params: { bms, tt, tsft, sft, lt },
      })
      example.value = response.data[0]
    }

    const setTitle = async () => {
      const cattleId = prediction.value?.cattleId || 'unknown'
      const shootingDate = prediction.value?.shootingDate.replaceAll('-', '') || 'unknown'
      document.title = `${shootingDate}-${cattleId}`
    }

    ;(async () => {
      await getPrediction()
      await getExample()
      await setTitle()
    })()

    return {
      prediction,
      example,
      moonAge,
      predictionUrl,
      printObj,
      display,
      formatDate,
      getRank5Percentage,
    }
  },
})
