
import { defineComponent } from 'vue'
import CattleImport from '@/components/CattleImport.vue'

export default defineComponent({
  name: 'Setting',
  components: {
    CattleImport,
  },
})
