
import { computed, defineComponent, ref } from 'vue'
import { genFileId } from 'element-plus'
import type { UploadInstance, UploadProps, UploadRawFile, UploadUserFile } from 'element-plus'

export default defineComponent({
  name: 'EchoInspectionUpload',
  setup() {
    const upload = ref<UploadInstance>()
    const filesRef = ref<UploadUserFile[]>()
    const uploadFile = computed(() => filesRef.value?.[0]?.raw)

    const onExceed: UploadProps['onExceed'] = (files) => {
      if (upload.value == null) {
        return
      }

      // Clear old file and start with new file
      upload.value.clearFiles()
      const file = files[0] as UploadRawFile
      file.uid = genFileId()
      upload.value.handleStart(file)
    }

    return {
      // refs
      upload,
      filesRef,
      // computed
      uploadFile,
      // methods
      onExceed,
    }
  },
})
