
import { defineComponent } from 'vue'
import Prediction from '@/components/Prediction.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Prediction,
  },
})
