
import axios from 'axios'
import { format } from 'date-fns'
import { defineComponent, inject, reactive } from 'vue'
import { AuthPlugin } from '@/auth'

// todo: 定義箇所をまとめる
interface PredictionResult {
  BMS: number
  BMS_L2ALLG74: number
  BMS_L2ALLGL: number
  BMS_L2G74: number
  BMS_L2GL: number
  BMS_L3ALLG74: number
  BMS_L3ALLGL: number
  BMS_L3G74: number
  BMS_L3GL: number
}

interface Prediction {
  id: number
  userId: string
  cattleId: string
  shotAt?: string
  shootingDate: string
  input: unknown
  result: PredictionResult
  createdAt?: string
  updatedAt?: string
}

export default defineComponent({
  name: 'List',
  setup() {
    const backendUrl: string | undefined = inject('BackendUrl')
    if (!backendUrl) {
      throw new Error('BackendUrl is not provided')
    }
    const auth: AuthPlugin | undefined = inject('Auth')
    if (!auth) {
      throw new Error('Auth plugin is not provided')
    }

    const predictions = reactive<Prediction[]>([])

    const formatDate = (date: string, pattern = 'yyyy-MM-dd HH:mm:ss') => {
      return format(new Date(date), pattern)
    }

    const getPredictions = async () => {
      const idToken = (await auth.getIdTokenClaims({}))?.__raw

      const response = await axios.get(`${backendUrl}/v1/prediction`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })

      predictions.splice(0, predictions.length, ...response.data)
      predictions.forEach((prediction) => {
        if (prediction.updatedAt) {
          prediction.updatedAt = formatDate(prediction.updatedAt)
        }
        if (prediction.shotAt) {
          prediction.shotAt = formatDate(prediction.shotAt)
        } else {
          prediction.shotAt = formatDate(prediction.shootingDate, 'yyyy-MM-dd')
        }
      })
    }
    getPredictions()

    return {
      predictions,
    }
  },
})
