
import axios from 'axios'
import { defineComponent, inject, ref } from 'vue'
import { AuthPlugin } from '@/auth'
import { genFileId } from 'element-plus'
import type { UploadInstance, UploadProps, UploadRawFile, UploadUserFile } from 'element-plus'

export default defineComponent({
  name: 'Import',
  setup() {
    const upload = ref<UploadInstance>()
    const filesRef = ref<UploadUserFile[]>()
    const isRunning = ref(false)

    const backendUrl: string | undefined = inject('BackendUrl')
    if (!backendUrl) {
      throw new Error('BackendUrl is not provided')
    }

    const auth: AuthPlugin | undefined = inject('Auth')
    if (!auth) {
      throw new Error('Auth plugin is not provided')
    }

    const onExceed: UploadProps['onExceed'] = (files) => {
      if (upload.value == null) {
        return
      }

      // Clear old file and start with new file
      upload.value.clearFiles()
      const file = files[0] as UploadRawFile
      file.uid = genFileId()
      upload.value.handleStart(file)
    }

    const importData = async () => {
      try {
        if (filesRef.value?.[0] == null) {
          return
        }

        isRunning.value = true

        const data = new FormData()
        data.append('file', filesRef.value[0].raw as UploadRawFile)

        const idToken = (await auth.getIdTokenClaims({}))?.__raw

        await axios.post(`${backendUrl}/v1/cattle`, data, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${idToken}`,
          },
        })
      } finally {
        isRunning.value = false
      }
    }

    return {
      // refs
      upload,
      filesRef,
      // methods
      onExceed,
      importData,
    }
  },
})
