import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.predictions,
      "default-sort": { prop: 'updatedAt', order: 'descending' },
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          fixed: "",
          prop: "updatedAt",
          label: "解析日時",
          sortable: ""
        }),
        _createVNode(_component_el_table_column, {
          fixed: "",
          prop: "user.name",
          label: "ユーザー名"
        }),
        _createVNode(_component_el_table_column, {
          fixed: "",
          prop: "cattleId",
          label: "個体識別番号"
        }),
        _createVNode(_component_el_table_column, {
          fixed: "",
          prop: "shotAt",
          label: "撮影日時",
          sortable: ""
        }),
        _createVNode(_component_el_table_column, {
          fixed: "",
          prop: "result.BMS",
          label: "推定BMS",
          sortable: ""
        }),
        _createVNode(_component_el_table_column, {
          fixed: "right",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_router_link, {
              to: { name: 'Detail', params: { id: scope.row.id } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, { size: "mini" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 詳細 ")
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}