import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "echo-json-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_upload, {
      ref: "upload",
      "file-list": _ctx.filesRef,
      "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filesRef) = $event)),
      "auto-upload": false,
      multiple: false,
      accept: "application/zip",
      limit: 1,
      "on-exceed": _ctx.onExceed
    }, {
      trigger: _withCtx(() => [
        _createVNode(_component_el_button, { type: "primary" }, {
          default: _withCtx(() => [
            _createTextVNode("select file")
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(" ZIPファイルをアップロードしてください ")
      ]),
      _: 1
    }, 8, ["file-list", "on-exceed"])
  ]))
}